import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vld-parent" }
const _hoisted_2 = { class: "card mb-5 mb-xl-10" }
const _hoisted_3 = {
  id: "kt_account_profile_details",
  class: "collapse show"
}
const _hoisted_4 = { class: "card-body border-top p-9 pt-2" }
const _hoisted_5 = { class: "row mb-5" }
const _hoisted_6 = { class: "col-lg-4 fv-row" }
const _hoisted_7 = { class: "row mb-3" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "row mb-3" }
const _hoisted_10 = { class: "col-2" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "col-2" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "col-2" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "col-2" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "col-2" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "col-lg-8 fv-row" }
const _hoisted_21 = {
  class: "row px-1",
  style: {"font-size":"25px","font-weight":"700"}
}
const _hoisted_22 = {
  class: "row mb-5 px-1",
  style: {"font-size":"25px","font-weight":"600","color":"blue"}
}
const _hoisted_23 = {
  class: "row mb-2 px-1",
  style: {"font-size":"17px","font-weight":"500"}
}
const _hoisted_24 = {
  class: "row mb-2 px-1",
  style: {"font-size":"17px","font-weight":"500"}
}
const _hoisted_25 = {
  class: "row mb-2 px-1",
  style: {"font-size":"17px","font-weight":"500"}
}
const _hoisted_26 = {
  class: "row mb-2 px-1 mt-2 pt-2",
  style: {"font-size":"17px","font-weight":"500"}
}
const _hoisted_27 = { class: "card-footer d-flex justify-content-end py-6 px-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_loading, {
        active: _ctx.isLoading,
        "is-full-page": _ctx.fullPage,
        color: _ctx.isColor,
        "background-color": _ctx.isBackgroundColor,
        opacity: _ctx.isOpacity
      }, null, 8, ["active", "is-full-page", "color", "background-color", "opacity"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  alt: "",
                  class: "img-fluid",
                  src: _ctx.detail.imageshow
                }, null, 8, _hoisted_8)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeImage(_ctx.detailImage.images1))),
                    alt: "",
                    class: "img-fluid",
                    src: _ctx.detailImage.images1
                  }, null, 8, _hoisted_11)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("img", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeImage(_ctx.detailImage.images2))),
                    alt: "",
                    class: "img-fluid",
                    src: _ctx.detailImage.images2
                  }, null, 8, _hoisted_13)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("img", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeImage(_ctx.detailImage.images3))),
                    alt: "",
                    class: "img-fluid",
                    src: _ctx.detailImage.images3
                  }, null, 8, _hoisted_15)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("img", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeImage(_ctx.detailImage.images4))),
                    alt: "",
                    class: "img-fluid",
                    src: _ctx.detailImage.images4
                  }, null, 8, _hoisted_17)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("img", {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeImage(_ctx.detailImage.images5))),
                    alt: "",
                    class: "img-fluid",
                    src: _ctx.detailImage.images5
                  }, null, 8, _hoisted_19)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.detail.name), 1),
              _createElementVNode("div", _hoisted_22, " Rp. " + _toDisplayString(_ctx.formatPrice(_ctx.detail.price)), 1),
              _createElementVNode("div", _hoisted_23, " Kategori : " + _toDisplayString(_ctx.detail.productCatagory), 1),
              _createElementVNode("div", _hoisted_24, " Stock : " + _toDisplayString(_ctx.detail.stock), 1),
              _createElementVNode("div", _hoisted_25, " Diskon : Rp. " + _toDisplayString(_ctx.formatPrice(_ctx.detail.discount)), 1),
              _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.detail.description), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("button", {
          class: "btn btn-primary me-3",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('/superadmin/data-product')))
        }, " Kembali ")
      ])
    ])
  ], 64))
}